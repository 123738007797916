import React from "react";

const Resume = ({ data }) => {
  if (data) {
    var skillmessage = data.skillmessage;
    var education = data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h3>{education.school}</h3>
          <p className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      );
    });
    var work = data.work.map(function (work) {
      return (
        <div key={work.company} className="flex flex-col max-sm:item-start">
          <h3>{work.company}</h3>
          <p className="info">
            {work.title}
            <span>&bull;</span> <em className="date">{work.years}</em>
          </p>
          <p>{work.description}</p>
        </div>
      );
    });
    var skills = data.skills.map(function (skills) {
      var className = "bar-expand " + skills.name.toLowerCase();
      return (
        <li key={skills.name}>
          <span style={{ width: skills.level }} className={className}></span>
          <em>{skills.name}</em>
        </li>
      );
    });
  }

  return (
    <section id="resume">
      <div className="row education">
        <div className="three columns header-col">
          <h1>
            <span>Education</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{education}</div>
          </div>
        </div>
      </div>

      <div className="row work">
        <div className="three columns header-col ">
          <h1>
            <span>Work</span>
          </h1>
        </div>

        <div className="nine columns main-col ">{work}</div>
      </div>

      <div className="row skill">
        <div className="three columns header-col">
          <h1>
            <span>Skills</span>
          </h1>
        </div>
        <div>
          <div className="flex flex-wrap gap-5 md:p-2 max-sm:gap-3 ">
            <a
              href="https://www.w3schools.com/css/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg"
                alt="css3"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://dart.dev" target="_blank" rel="noreferrer">
              <img
                src="https://www.vectorlogo.zone/logos/dartlang/dartlang-icon.svg"
                alt="dart"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://expressjs.com" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original-wordmark.svg"
                alt="express"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://www.figma.com/" target="_blank" rel="noreferrer">
              <img
                src="https://www.vectorlogo.zone/logos/figma/figma-icon.svg"
                alt="figma"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a
              href="https://firebase.google.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg"
                alt="firebase"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://flutter.dev" target="_blank" rel="noreferrer">
              <img
                src="https://www.vectorlogo.zone/logos/flutterio/flutterio-icon.svg"
                alt="flutter"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://git-scm.com/" target="_blank" rel="noreferrer">
              <img
                src="https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg"
                alt="git"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://www.w3.org/html/" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg"
                alt="html5"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://www.java.com" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg"
                alt="java"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"
                alt="javascript"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://www.linux.org/" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/linux/linux-original.svg"
                alt="linux"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://www.mongodb.com/" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg"
                alt="mongodb"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a
              href="https://www.microsoft.com/en-us/sql-server"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.svgrepo.com/show/303229/microsoft-sql-server-logo.svg"
                alt="mssql"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://www.mysql.com/" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg"
                alt="mysql"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://nestjs.com/" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nestjs/nestjs-original.svg"
                alt="nestjs"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://nextjs.org/" target="_blank" rel="noreferrer">
              <img
                src="https://cdn.worldvectorlogo.com/logos/nextjs-2.svg"
                alt="nextjs"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://nodejs.org" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg"
                alt="nodejs"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://www.php.net" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/php/php-original.svg"
                alt="php"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a
              href="https://www.postgresql.org"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original-wordmark.svg"
                alt="postgresql"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg"
                alt="react"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://redux.js.org" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/redux/redux-original.svg"
                alt="redux"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://sass-lang.com" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/sass/sass-original.svg"
                alt="sass"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://svelte.dev" target="_blank" rel="noreferrer">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/1/1b/Svelte_Logo.svg"
                alt="svelte"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a href="https://tailwindcss.com/" target="_blank" rel="noreferrer">
              <img
                src="https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg"
                alt="tailwind"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
            <a
              href="https://www.typescriptlang.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg"
                alt="typescript"
                className="h-32 w-32 max-sm:w-24 max-sm:h-24"
              />
            </a>
          </div>
        </div>
        {/* <div className="nine columns main-col">
          <p>{skillmessage}</p>

          <div className="bars">
            <ul className="skills">{skills}</ul>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Resume;
