import React from "react";

const Portfolio = ({ data }) => {
  if (data) {
    var projects = data.projects.map(function (projects) {
      var projectImage = "images/portfolio/" + projects.image;
      return (
        <div key={projects.title} className="h-[640px] max-w-[350px] mb-5 p-3 bg-white rounded-lg hover:rotate-6">
           <img alt={projects.title} src={projectImage} className="h-[300px] w-full rounded-lg" />
           <div className="">
                <div className="my-5 flex flex-col gap-3 justify-between h-full">
                  <h5 className="font-semibold text-3xl  ">{projects.title}</h5>
                  <p className="border bg-blue-400 rounded-lg  text-white p-2  self-start mt-2">{projects.category}</p>
            
                  <p className="font-bold" >{projects.description}
                  </p>
                 
                  </div>
                
            
              </div>
          {/* <div className="item-wrap">
            <a href={projects.url} title={projects.title}>
              <img alt={projects.title} src={projectImage} className="h-96 w-full" />
              <div className="overlay">
                <div className="portfolio-item-meta">
                  <h5>{projects.title}</h5>
                  <p>{projects.category}</p>
                </div>
              </div>
              <div className="link-icon">
                <i className="fa fa-link"></i>
              </div>
            </a>
          </div> */}
        </div>
      );
    });
  }

  return (
    <section id="portfolio" >
      <div className="  ">
        <div className="twedlve cdolumns cdollapsed ">
          <h1 className="font-bold py-10 text-3xl text-black text-center ">Check Out Some of My Works.</h1>

          <div
            id="portfolio-wrapper"
            className="bgrid-quarters s-bgrid-thirds justify-around cf flex max-md:flex-col max-md:items-center gap-5"
          >
            {projects}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
