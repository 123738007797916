import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Ticker from 'react-ticker'
const Testimonials = ({ data }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  if (data) {
    var testimonials = data.testimonials.map(function (testimonials,i) {
      return (
       <Ticker speed={5}   >
         {({ index }) => (
            <div className="flex m-4">
            <div className="max-w-[700px] mx-5 p-6 rounded-xl my-5  text-white bg-slate-500 ">
            <blockquote>
            {testimonials.text}
            </blockquote>
          
            <cite className="mt-2">
                          {testimonials.user}</cite>
            </div>
            
               {/* <div
          key={i}bui
          className="border-[5px]  border-black mb-2 text-black rounded-lg p-3 bg-blue-400"
        >
          <p>{testimonials.text}</p>
          <cite>{testimonials.user}</cite>
      //   </div> */}
           </div>
        )}
 

       </Ticker>
       
     
       
      );
    });
  }

  return (
    <section id="testimonialss">
      <div className="text-containser bg-white h-max">
        <div className="ro">
          <div className="twder-col text-center text-5xl  pt-2">
            <h1 className="font-serif">
            Client Testimonials
            </h1>
          </div>

          <div className="tener border w-[100vw-300px]">
     {testimonials}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
