import React from "react";

const About = ({ data }) => {
  if (data) {
    var name = data.name;
    var profilepic = "images/" + data.image;
    var bio = data.bio;
    var street = data.address.street;
    var city = data.address.city;
    var state = data.address.state;
    var zip = data.address.zip;
    var phone = data.phone;
    var email = data.email;
    var resumeDownload = data.resumedownload;
  }

  return (
    <section id="about">
      <div className="flex max-sm:flex-col max-sm:items-center mx-auto justify-center max-w-[1200px]">
        <div className="three columns">
          <img
            className="rounded-full max-sm:w-52"
            src={profilepic}
            alt="Abdurahman Pic"
          />
        </div>
        <div className="nine columns main-col mt-2 ">
          <h2 className="max-sm:text-center">About Me</h2>

          <p>{bio}</p>
          <div className="mt-3">
            <div className="  self-start mb-16">
              <h2>Contact Details</h2>
              <p className="address">
                <span>{name}</span>
                <br />
                <span>
                  {street}
                  <br />
                  {city} {state}, {zip}
                </span>
                <br />
                <span>{phone}</span>
            
                <span>{email}</span>
              </p>
            </div>
     
              
                <a href="./Abdurahman-CV.pdf" className="bg-[#444] p-8 rounded-lg mt-6 font-bold font-sans" download={true}>
                  <i className="fa fa-download mr-5"></i>Download Resume
                </a>
              
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
